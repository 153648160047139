//  animation: fadeIn 1s ease-out 0.2s 1 forwards;
@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

//  use: animation: fadeAndFly 0.5s cubic-bezier(0,.72,.44,1.01) 1.1s 1 forwards;
@keyframes fadeAndFly {
    from {
        opacity:0;
        transform: translate3d(0, 30px, 0);
    }
    to {
        opacity:1;
        transform: translate3d(0, 0, 0);
    }
}
