//  fisier pentru clase create manual

.indent-10 {
    text-indent: 10px;
}

.indent-15 {
    text-indent: 15px;
}

.indent-20 {
    text-indent: 20px;
}

.width-fit-content {
    width: fit-content;
}

@media (min-width: 576px) {
    .indent-40 {
        text-indent: 40px;
    }
  }