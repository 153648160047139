.navbar{
    background-color: rgba(101, 64, 39, 0.96);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 3;
    box-shadow: 0px 1px 4px rgba(4, 5, 6, 0.08),
                0px 2px 4px rgba(4, 5, 6, 0.1),
                0px 4px 8px rgba(4, 5, 6, 0.04);

    -webkit-backdrop-filter: blur(24px);
    backdrop-filter: blur(24px);
    min-height: 64px;

    .navbar-mobile-btn-content{
        @media (max-width: 991px) {
            padding-top: 7px;
            padding-bottom: 7px;
        }
    }

    .nav-link{
        @extend .text-white, .p-3_5, .text-center;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        padding-right: 32px;
        padding-left: 32px;
        border: 1px solid transparent;
        position: relative;
        &:before{
            content: '';
            position: absolute;
            transition: .35s ease-in-out;
            width: 0;
            height: 2px;
            background-color: #ffffff;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
        }
        &:hover{
            &:before{
                width: 100%;
            }
        }
    }

    .navbar-collapse{
        max-height: calc(100vh - 64px);
        overflow-y: auto;
    }

    .navbar-toggler{
        border-color: transparent;
    }

    .navbar-brand-icon{
        margin-right: -12px;
        -moz-transform: scale(-1, 1);
        -webkit-transform: scale(-1, 1);
        -o-transform: scale(-1, 1);
        -ms-transform: scale(-1, 1);
        transform: scale(-1, 1);
    }
}
