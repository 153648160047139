.betel-contact-info{
    p{
        display: flex;
        flex-direction: row-reverse;
        justify-content: flex-end;

        @media (min-width: 992px) {
            flex-direction: row;
        }
    }
}

input, textarea{
    box-shadow: inset 200px 200px 100px #fff!important;
    filter: none;
    &:focus{
        box-shadow: inset 200px 200px 100px #fff!important;
        border-color: #80bdff !important;
    }
}

.error-text{
    font-size: 12px;
    line-height: 18px;
    @extend .text-danger;
}

.btn-contact-form{
    background-color: rgba(101, 64, 39, 0.9);
    border-color: rgba(101, 64, 39, 0.9);

    &:hover{
        background-color: rgb(101, 64, 39);
        border-color: rgb(101, 64, 39);
    }

    &:focus, &:active{
        background-color: rgb(101, 64, 39) !important;
        border-color: rgb(101, 64, 39) !important;
    }
}
