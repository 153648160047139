.betel-card{
    border-radius: 8px;
    background-color: #fff;
    transition: all .5s ease;

    &:hover{
        box-shadow: 0px 2px 4px rgba(4, 5, 6, 0.15), 0px 8px 16px rgba(4, 5, 6, 0.1), 0px 16px 24px rgba(4, 5, 6, 0.1);
        border-radius: 8px;

        .betel-card-image-top {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    .card-image{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        .betel-card-image-top{
            position: relative;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            height: 250px;
            width: 100%;
            transition: transform .5s ease, -webkit-transform .5s ease;
        }
    }

    .card-footer{
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        background-color: unset;
        border: none;
    }
}
