@import "./../bootstrap/scss/bootstrap.scss";
@import "./../bootstrap-icons/font/bootstrap-icons.css";
@import "components/animations";
@import "./components/nav.scss";
@import "./components/header.scss";
@import "./components/carousel.scss";
@import "./components/card.scss";
@import "./components/betel-btn.scss";
@import "./components/contact.scss";
@import "./components/salut-section.scss";
@import "./components/manual-classes.scss";
@import "./components/gallery.scss";

// sbc header
@import "./components/simpleHeader.scss";

html {
    scroll-behavior: smooth;
}

body{
    font-family: Roboto, sans-serif !important;
    color: #2a3546;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.6;
    text-align: left;
    background-color: #fff;
}

.bg-gray {
    background-color: #F5F7F9 !important;
}

.bg-gray-after {
    &:after{
        background-color: #F5F7F9 !important;
    }
}

button{
    &:focus, &:active{
        box-shadow: none !important;
    }
}

.border-lg-right{
    @media (min-width: 992px) {
        border-right: 1px solid #dee2e6 !important;
    }
}

a:hover{
    text-decoration: unset;
}

.color-brown-icon{
    color: #9c5120;
}

.color-brown{
    color: rgba(101, 64, 39, 0.9);
}

.bg-brown-before-after {
    &::before, &::after {
        background-color: rgba(234, 225, 218, 0.9) !important;
    }
}

.bg-brown{
    background-color: rgba(234, 225, 218, 0.9) !important;
}

$text-align: left, right, center;
$responsive-width-size: xl 1200px, lg 992px, md 768px, sm 575px;
$font-size: h6 1rem, h5 1.25rem, h4 1.5rem, h3 1.75rem, h2 2rem, h1 2.5rem;
$font-size-px: 8, 10, 12, 14, 16, 20, 24, 28, 32, 36, 40, 42, 44, 48, 52, 56, 60, 64, 72;
$percentage: 100, 75, 50, 40, 25, 10;
$z-index-values: 1, 0;

@each $size in $font-size-px {
    .font-size-#{$size} {
        font-size: $size * 1px !important;
    }

    .line-height-#{$size} {
        line-height: $size * 1px !important;
    }
}

@each $responsive, $value in $responsive-width-size {

    @media (min-width: $value) {
        .height-#{$responsive} {
            height: $value / 3;
        }
    }

    @media (max-width: $value) {

        @each $percent in $percentage {
            .w-#{$responsive}-#{$percent} {
                width: $percent * 1% !important;
            }
        }

        @each $size in $font-size-px {
            .font-size-#{$responsive}-#{$size} {
                font-size: $size * 1px !important;
            }
        }

        @each $size in $font-size-px {
            .line-height-#{$responsive}-#{$size} {
                line-height: $size * 1px !important;
            }
        }

        @each $size, $font-value in $font-size {
            .text-#{$responsive}-#{$size} {
                font-size: $font-value !important;
            }
        }

        @each $align in $text-align {
            .text-align-#{$responsive}-#{$align} {
                text-align: $align !important;
            }
        }

        .py-#{$responsive}-2_5 {
            padding-top: 12px !important;
            padding-bottom: 12px !important;
        }
    }
}

.text-color-brown{
    color: #ab5009;
}

.betel-ul-arrow{
    list-style: none;
    li{
        position: relative;
        line-height: 24px;

        &:before{
            content: "\f138";
            position: absolute;
            font-family: bootstrap-icons !important;
            font-style: normal;
            font-weight: normal !important;
            font-variant: normal;
            text-transform: none;
            vertical-align: -.125em;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            color: #ae510b;
            left: -6px;
            transform: translateX(-100%);
        }
    }
}

.betel-title-section{
    position: relative;
    display: inline-flex;
    padding-bottom: 12px;

    &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #9c5120;
        bottom: 0;
    }

    &:after {
        background-image: url('./../img/porumbel.svg');
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: contain;
        background-color: #ffffff !important;
        content: "";
        position: absolute;
        width: 41px;
        height: 26px;
        left: 50%;
        bottom: 0;
        transform: translateX(-50%) translateY(50%);
    }
}

.betel-row-bg-clip-path-top-bottom {
    position: relative;
    margin-top: 16px;
    margin-bottom: 16px;

    @media (min-width: 576px) {
        margin-top: 40px;
        margin-bottom: 40px;
    }

    &::before, &::after {
        background-color: #f6f7f9;
        position: absolute;
        width: 100%;
        content: "";
    }

    &::before {
        height: 16px;
        top: -16px;
        -webkit-clip-path: polygon(0 16px, 0 100%, 100% 100%, 100% 0);
        clip-path: polygon(0 16px, 0 100%, 100% 100%, 100% 0);

        @media (min-width: 576px) {
            height: 40px;
            top: -40px;
            -webkit-clip-path: polygon(0 40px, 0 100%, 100% 100%, 100% 0);
            clip-path: polygon(0 40px, 0 100%, 100% 100%, 100% 0);
        }
    }

    &::after {
        height: 16px;
        bottom: -16px;
        -webkit-clip-path: polygon(16px 0, 0 100%, 100% 100%, 100% 0);
        clip-path: polygon(0 0, 100% 16px, 100% 0%, 100% 0);

        @media (min-width: 576px) {
            height: 40px;
            bottom: -40px;
            -webkit-clip-path: polygon(40px 0, 0 100%, 100% 100%, 100% 0);
            clip-path: polygon(0 0, 100% 40px, 100% 0%, 100% 0);
        }
    }
}

.betel-row-bg-clip-path-top-bottom-reverse {
    @extend .betel-row-bg-clip-path-top-bottom;

    &:before{
        -webkit-clip-path: polygon(0 0px, 0px 100%, 100% 100%, 100% 16px);
        clip-path: polygon(0 0px, 0px 100%, 100% 100%, 100% 16px);

        @media (min-width: 576px) {
            -webkit-clip-path: polygon(0 0px, 0px 100%, 100% 100%, 100% 40px);
            clip-path: polygon(0 0px, 0px 100%, 100% 100%, 100% 40px);
        }
    }

    &:after{
        -webkit-clip-path: polygon(0 0, 0% 16px, 100% 0%, 100% 0);
        clip-path: polygon(0 -1px, 0% 16px, 100% 0%, 100% 0);

        @media (min-width: 576px) {
            -webkit-clip-path: polygon(0 0, 0% 40px, 100% 0%, 100% 0);
            clip-path: polygon(0 -1px, 0% 40px, 100% 0%, 100% 0);
        }
    }
}
