.portfolio-menu{
  text-align:center;
}

.portfolio-menu ul{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.portfolio-menu ul li{
  display:inline-block;
  margin:0;
  list-style:none;
  padding:10px 15px;
  cursor:pointer;
  -webkit-transition:all 05s ease;
  -moz-transition:all 05s ease;
  -ms-transition:all 05s ease;
  -o-transition:all 05s ease;
  transition:all .5s ease;
}

.portfolio-item{
  /*width:100%;*/
  margin-right: -8px;
  margin-left: -8px;
  display: flex;
}

.portfolio-item .item{
  /*width:303px;*/
  float:left;
  margin-bottom: 16px;
  padding-left: 8px;
  padding-right: 8px;

  a{
    display: flex;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
    position: relative;

    &:hover{
      &:before{
        content: "\f341";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #0000002e;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        color: white;

        // icon
        font-family: bootstrap-icons !important;
        font-style: normal;
        font-weight: normal !important;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        vertical-align: -0.125em;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
  }
}
