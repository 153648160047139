.betel-salut-section{
    .betel-salut-bg{
        background-image: url("./../img/betel-salut-bg.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        .betel-salut-card{
            width: 680px;
            max-width: 100%;
            padding: 40px;
            background: white;
            opacity: 0.7;
            border-radius: 8px;
            margin: auto;

            p{
                font-family: "Courier New", Courier, monospace;
                font-size: 28px;
                font-weight: 400;
                font-style: italic;
                margin-top: 10px;
                margin-right: 0;
                margin-bottom: 0px;
                margin-left: 0;
                line-height: 32px;
                text-indent: 40px;
                padding: 4%;

                &:before{
                    background-image: url(../img/quote_mark.png);
                    background-repeat: no-repeat;
                    background-position: left top;
                }

                &:after{
                    background-image: url(../img/quote_mark.png);
                    background-repeat: no-repeat;
                    background-position: right bottom;
                }
            }
        }
    }
}
