.betel_simpleHeader{
  h1, h2, h3, h4, h5, h6 { color: #25292a; margin: 0px 0px 10px 0px; font-family: 'Overpass', sans-serif; font-weight: 700; letter-spacing: -1px; line-height: 1; }
  p { margin: 0 0 20px; line-height: 1.7; }
  p:last-child { margin: 0px; }

  .page-header {
    background-image: url(https://betelarad.ro/img/sbc/IMG_3309.JPG);
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    .page-header-overlay{
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.34);
    }
  }
  .page-caption {
    padding-top: 15vw;
    padding-bottom: 16vw;
  }
  .page-title { font-size: 46px; line-height: 1; color: #fff; font-weight: 600; text-align: center; }

  .card-section { position: relative; bottom: 60px; }
  .card-block {
    border-radius: 8px;
    padding: 32px 16px;
    @media (min-width: 992px) {
      padding: 80px;
    }
  }
  .section-title { margin-bottom: 60px; }
}