.gallery {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.gallery-container {
    align-items: center;
    display: flex;
    //min-height: 400px;
    margin: 0 auto;
    max-width: 1000px;
    position: relative;
    touch-action: pan-y;
}

.gallery-item {
    opacity: 0;
    position: absolute;
    transition: all 0.3s ease-in-out;
    z-index: 0;
    background-color: #ffffff;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 1px solid rgba(0, 0, 0, 0.125);

    @media (min-width: 576px) {
        max-width: 100%;
    }
}

.gallery-item-1 {
    left: 50%;
    opacity: .4;
    transform: translateX(-50%) scale(0.1);
}

.gallery-item-2,
.gallery-item-4 {
    opacity: 1;
    z-index: 1;
    transform: translateX(-50%) scale(0.8);
}

.gallery-item-2 {
    left: -50%;
    box-shadow: 0px 8px 48px rgba(2, 51, 130, .1);

    @media (min-width: 992px){
        left: 25%;
    }

    @media (min-width: 1200px){
        left: 13%;
    }
}

.gallery-item-3 {
    box-shadow: 0px 8px 48px rgba(2, 51, 130, .1);
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    z-index: 2;

    &:hover{
        box-shadow: 0px 8px 48px rgba(2, 51, 130,  0.20);
    }
}

.gallery-item-4 {
    left: 150%;
    box-shadow: 0px 8px 48px rgba(2, 51, 130, .1);

    @media (min-width: 992px){
        left: 75%;
    }

    @media (min-width: 1200px){
        left: 87%;
    }
}

.gallery-item-5 {
    left: 50%;
    opacity: .4;
    transform: translateX(-50%) scale(0.1);
}

.gallery-item-6 {
    left: 50%;
    opacity: .4;
    transform: translateX(-50%) scale(0.1);
}

.gallery-controls {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    justify-content: space-between;
}

.gallery-controls button {
    border: 0;
    cursor: pointer;
    font-size: 16px;
    background-color: #FFFFFF;
    padding: 0;
    text-transform: capitalize;

    i{
        font-size: 30px;
        &:before {
            color: #B6C1D3;
        }
    }
}

.gallery-controls button:focus {
    outline: none;
}

.gallery-nav-container {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
    width: 100%;
    position: absolute;
    bottom: -30px;
    transform: translateY(100%);
    margin: 0;

}

.gallery-nav-container li {
    background: #E6E9F0;
    border-radius: 50%;
    height: 10px;
    margin: 0 10px;
    width: 10px;
}

.gallery-nav-container li.gallery-nav-3 {
    background: #B6C1D3;
}





//* CUSTOM ELEMENTS *//

.gallery-item{
    .carousel-item-map{
        width: 100%;
        height: 200px;
        border-radius: 4px;
        margin: auto;
    }

    .card-buttons{
        gap: 6px;
    }
}
