.betel-btn{
    background-color: rgba(234, 225, 218, 0.9);
    color: rgba(101, 64, 39, 0.9);
    font-size: 16px;
    line-height: 16px;
    padding: 6px 8px 6px 16px;
    border-radius: 72px;
    transition: all 150ms ease-in-out;
    display: inline-flex;
    align-items: center;

    &:hover {
        color: rgb(76, 40, 16);
        i {
            margin-left: 16px;
        }
    }

    i{
        display: inline-flex;
        background-color: rgba(101, 64, 39, 0.9);
        border-radius: 50%;
        color: #ffffff;
        padding: 3px;
        margin-left: 8px;
        transition: all 150ms ease-in-out;
    }
}
