.betel-header{
    position: relative;
    background-image: url("./../img/header_betel_interior.jpeg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    height: calc(100vh - 64px);
    width: 100%;
    //margin-top: 64px;

    &:before{
        content: '';
        position: absolute;
    }

    @media (min-width: 992px) {
        background-attachment: fixed;
    }

    p{
        z-index: 1;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
    }

    .betel-header-bg-color{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.08);
    }

    .betel-header-title{
        font-family: 'Monotype Corsiva', serif;
        font-size: 100px;
        letter-spacing: 5px;
        display: block;
        font-style: normal;
        padding-top: 15px;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        text-shadow: 0.06em 0.06em 0 #00000033;
        opacity:0;
        animation: fadeIn 1s ease-out 0.2s 1 forwards;
    }
}
